var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"kbiz-account-modal","hide-footer":"","size":"xl","title":"ลงทะเบียนระบบออโต้ KBiz"}},[_c('b-overlay',{attrs:{"show":_vm.isFetching}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Username"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"name":"username","placeholder":"ยูสเซอร์เนม KBiz","type":"text"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Password"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"name":"password","placeholder":"รหัสผ่าน KBiz","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"secret-key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Secret Key"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"name":"secret-key","placeholder":"Secret Key","type":"text"},model:{value:(_vm.form.secretKey),callback:function ($$v) {_vm.$set(_vm.form, "secretKey", $$v)},expression:"form.secretKey"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto",attrs:{"disabled":_vm.isFetching,"type":"submit","variant":"primary"}},[_vm._v(" ลงทะเบียน ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }